var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"pageTable"}},[_c('v-overlay',{attrs:{"value":_vm.loadingLottie,"z-index":999}},[_c('div',{staticClass:"align-center justify-center text-center"},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":100,"width":7,"color":"#2DB9D1"}},[_c('v-img',{attrs:{"src":"/static/icon/favicon.ico","width":60}})],1),_c('p',{staticClass:"mt-5"},[_vm._v(_vm._s(_vm.fraseLoading))])],1)]),_c('v-container',{attrs:{"xl":"","fluid":""}},[_c('v-row',[_c('div',{staticStyle:{"display":"flex","width":"100%","justify-content":"flex-start"}},[_c('hr',{staticClass:"vr"}),_c('p',{staticClass:"tblName pb-2"},[_vm._v("Reporte de timbres")])]),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"12","lg":"12","xl":"12"}},[_c('v-card',{staticStyle:{"border-radius":"20px"},attrs:{"elevation":"0"}},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card-text',{staticClass:"px-30px pt-30px pb-0"},[_c('v-row',{staticClass:"my-0"},[(_vm.rol=='root')?_c('v-col',{staticClass:"py-0 my-0",attrs:{"cols":"12","xs":"12","sm":"6","md":"6","lg":"6","xl":"6"}},[_c('ValidationProvider',{attrs:{"name":"Cliente","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"outlined":"","label":"Cliente","items":_vm.clientes,"item-text":"nombre","item-value":"id","persistent-hint":"","required":"","error-messages":errors},model:{value:(_vm.cliente_value),callback:function ($$v) {_vm.cliente_value=$$v},expression:"cliente_value"}})]}}],null,true)})],1):_vm._e(),_c('v-col',{staticClass:"py-0 my-0",attrs:{"cols":"12","xs":"12","sm":_vm.rol=='root'?6:12,"md":_vm.rol=='root'?6:12,"lg":_vm.rol=='root'?6:12,"xl":_vm.rol=='root'?6:12}},[_c('VuetifyMultipleAutocomplete',{ref:"multRegistroPatronal",attrs:{"outlined":"","items":_vm.registrosPatronales,"item-text":"nombre","item-value":"id","label":"Registros patronales"},on:{"dataMultiple":function (data){ return _vm.registrosPatronalesSeleccionados = data; }}})],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"6","lg":"6","xl":"6"}},[_c('VuetifyDataPicker',{ref:"FechaInicio",attrs:{"rules":"required","outlined":"","valorDefecto":_vm.fecha_inicio,"label":"Fecha inicio","placeholder":"dd/mm/aaaa"},model:{value:(_vm.fecha_inicio),callback:function ($$v) {_vm.fecha_inicio=$$v},expression:"fecha_inicio"}})],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"6","lg":"6","xl":"6"}},[_c('VuetifyDataPicker',{ref:"FechaFin",attrs:{"rules":"required","outlined":"","valorDefecto":_vm.fecha_fin,"label":"Fecha fin","placeholder":"Fecha fin"},model:{value:(_vm.fecha_fin),callback:function ($$v) {_vm.fecha_fin=$$v},expression:"fecha_fin"}})],1)],1)],1),_c('v-card-actions',{staticClass:"d-flex justify-end pa-30px"},[_c('v-btn',{staticClass:"btnGuardar",attrs:{"loading":_vm.isLoadingConsultar,"disabled":invalid},on:{"click":function($event){return _vm.consultar()}}},[_vm._v(" Consultar ")]),_c('v-btn',{staticClass:"btnGuardar",attrs:{"loading":_vm.isLoadingConsultar,"disabled":invalid},on:{"click":function($event){return _vm.descargar()}}},[_vm._v(" Descargar ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"pb-0 pl-2 outlined_v_icon",attrs:{"slot":"activator","color":"#96999A"},slot:"activator"},on),[_vm._v("info")])]}}],null,true)},[_c('span',{staticClass:"pb-7 textTooltip"},[_vm._v(" Para un detalle mas general descargar el reporte. ")])])],1)]}}])})],1)],1)],1),_c('v-row',[_c('simple-table',{staticClass:"tablaWidth",attrs:{"rows":_vm.datosTimbres,"columns":_vm.headersTable,"perPage":[10, 25, 50, 100],"shortPagination":"","emptyTable":'emptyTableWhite',"classes":_vm.tableClasses},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return _c('tbody',{staticClass:"tbl-incidencias"},_vm._l((data),function(item,index){return _c('tr',{key:item.id},[_c('td',{staticStyle:{"background-color":"#ffffff !important"}},[_c('div',[_vm._v(_vm._s(item.nombre_registro_patronal))])]),_c('td',{staticStyle:{"background-color":"#ffffff !important"}},[_c('div',[_vm._v(_vm._s(item.nombre_completo))])]),_c('td',{staticStyle:{"background-color":"#ffffff !important"}},[_c('div',[_vm._v(_vm._s(item.numero))])]),_c('td',{staticStyle:{"background-color":"#ffffff !important"}},[_c('div',[_vm._v(_vm._s(item.fecha_format))])])])}),0)}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }